/* Left-Aligned Hero Section */
.left-hero-section {
    background-color: #ffffff; /* White background */
    padding: 80px 0;
  }
  
  /* Left Hero Title */
  .left-hero-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: black;
  }
  
  /* Left Hero Subtitle */
  .left-hero-subtitle {
    font-size: 1.2rem;
    color: #333;
    max-width: 500px;
  }
  
  /* Left Hero Button */
  .left-custom-btn {
    background-color: #de0f3f !important;
    color: white !important;
    padding: 10px 20px;
    border: none;
    transition: background 0.3s;
  }
  
  .left-custom-btn:hover {
    background-color: #b00c30 !important;
  }
  
  /* Left Hero Image */
  .left-hero-img {
    max-width: 100%;
    height: auto;
    border-radius: 15px;
  }
  
  /* Mobile Adjustments */
  @media (max-width: 768px) {
    .left-hero-title {
      font-size: 2rem;
    }
    
    .left-hero-subtitle {
      font-size: 1.2rem;
    }
    
    .left-hero-buttons .btn {
      font-size: 0.9rem;
      padding: 10px 15px;
    }
    
    /* Center text when stacked */
    .left-hero-section .row {
      text-align: center;
    }
  }
  