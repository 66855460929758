/* Footer Styling */
.footer-section {
    background-color: #282a35; /* Dark background */
    color: white;
    padding: 40px 0;
  }
  
  /* Footer Logo */
  .footer-logo {
    height: 50px; /* Matches the navbar logo */
  }
  
  /* Footer Links */
  .footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .footer-links li {
    display: inline;
  }
  
  .footer-links a {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-links a:hover {
    color: #de0f3f; /* Matches your theme */
  }
  
  /* Social Media Icons */
  .social-icon {
    color: white;
    font-size: 1.5rem;
    margin: 0 10px;
    transition: color 0.3s;
  }
  
  .social-icon:hover {
    color: #de0f3f;
  }
  
  /* Footer Divider */
  .footer-divider {
    border-color: rgba(255, 255, 255, 0.2);
    margin: 20px 0;
  }
  
  /* Footer Text */
  .footer-text {
    font-size: 0.9rem;
    opacity: 0.8;
  }
  